// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Copyright (C) 2018 Airbus CyberSecurity (SAS)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the Server Side Public License, version 1,
 * as published by MongoDB, Inc.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * Server Side Public License for more details.
 *
 * You should have received a copy of the Server Side Public License
 * along with this program. If not, see
 * <http://www.mongodb.com/licensing/server-side-public-license>.
 */

/* TODO should probably either reuse predefined graylog components or introduce our own components if really necessary */
.W4VDSG9D_CwppjrICZsm {
    border-right: #D1D1D1 solid 1px;
}

.JVn4rYi5ZaJ7MjhXjmfN {
    border-left: #D1D1D1 solid 1px;
    margin-left: -1px;
}

.wmulSSZseoDuMY62htOT {
    border-bottom: #D1D1D1 solid 1px;
}
`, "",{"version":3,"sources":["webpack://./src/web/wizard/components/rules/AlertRuleForm.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;EAeE;;AAEF,wHAAwH;AACxH;IACI,+BAA+B;AACnC;;AAEA;IACI,8BAA8B;IAC9B,iBAAiB;AACrB;;AAEA;IACI,gCAAgC;AACpC","sourcesContent":["/**\n * Copyright (C) 2018 Airbus CyberSecurity (SAS)\n *\n * This program is free software: you can redistribute it and/or modify\n * it under the terms of the Server Side Public License, version 1,\n * as published by MongoDB, Inc.\n *\n * This program is distributed in the hope that it will be useful,\n * but WITHOUT ANY WARRANTY; without even the implied warranty of\n * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the\n * Server Side Public License for more details.\n *\n * You should have received a copy of the Server Side Public License\n * along with this program. If not, see\n * <http://www.mongodb.com/licensing/server-side-public-license>.\n */\n\n/* TODO should probably either reuse predefined graylog components or introduce our own components if really necessary */\n:local(.subnavigation) {\n    border-right: #D1D1D1 solid 1px;\n}\n\n:local(.contentpane) {\n    border-left: #D1D1D1 solid 1px;\n    margin-left: -1px;\n}\n\n:local(.divider) {\n    border-bottom: #D1D1D1 solid 1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subnavigation": `W4VDSG9D_CwppjrICZsm`,
	"contentpane": `JVn4rYi5ZaJ7MjhXjmfN`,
	"divider": `wmulSSZseoDuMY62htOT`
};
export default ___CSS_LOADER_EXPORT___;
